import React from 'react';
import RightNavBar from '../components/docs/RightNavBar/index';
import { Box, styled } from '@mui/system';
import SEO from '../components/common/Seo';
import theme, { BREAKPOINTS, MOBILE_SPACING } from '../utils/theme';
import Markdoc from '@markdoc/markdoc';
import { customConfig, components } from '../schema/config';
import {readFileSync, readdirSync, statSync, existsSync} from 'fs';
import { getPrivatePage } from '../components/common/SecurityWrapper';
import { appendRouteToUrl, getBaseSettings } from '../utils/helper';
import { sortSDKNavItems } from '../utils/helpers';
import NotFoundPage from './404';
import { replaceRoutes } from '../utils/mapRoutes';
import { logger } from '../utils/logger';

const useStyles = {
  outerBox: {
    display: 'flex',
    flex: '1 1 0%',
    width: '100%',
  },
  innerBox: {
    display: 'flex',
  },
  centerBoxWithRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      width: 'calc(100% - 410px)',
      paddingLeft: theme.spacing(10),
    },
  },
  centerBoxWithNoRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: '410px',
      margin: 'auto',
    },
  },
};


const InnerBox = styled('div')(({ theme }) => ({
  maxWidth: '989px',
  margin: '44px auto',
  marginBottom: '0px',
  [BREAKPOINTS.MOBILE]: {
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: 'auto 20px',
    marginTop: theme.spacing(3),
  },
  [BREAKPOINTS.DESKTOP]: {
    width: 'calc(100% - 88px)',
  },
}));

const SideBar = styled('div')(({ theme }) => ({
  width: '290px',
  [BREAKPOINTS.MOBILE]: {
    display: 'none',
  },
}));

const RightBar = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  marginLeft: '40px',
  width: '290px',
  maxHeight:'calc(100% - 150px)',
  overflow:'scroll',
  [BREAKPOINTS.MOBILE]: {
    display: 'none',
  },
}));

const buildSEO = (frontmatter, excerpt, headings) => {
  let title = '';
  let description = '';
  let docType='';
  if (frontmatter && frontmatter.title) {
    title = frontmatter.title;
  } else if (headings) {
    const headerList = headings.filter((header) => header.depth === 1);
    title = headerList && headerList[0] ? headerList[0].value : '';
  } else {
    title = '';
  }

  if (frontmatter && frontmatter.description) {
    description = frontmatter.description;
  } else {
    description = excerpt;
  }

  if(frontmatter && frontmatter.docType){
    docType=frontmatter.docType;
  }
  else{
    docType='';
  }

  return { title: title, description: description, docType: docType };
};


function generateHeadings(content, headings, rightNavItems) {
  content.children.forEach(item => {
    if(item.name === 'H2' || item.name === 'H3')
    {
      rightNavItems.push({ url: '#'+item.attributes.id, value: item.children[0], depth: item.attributes.level });
    }
    else if(item.name === 'H1')
    {
      headings.push({ url: '#'+item.attributes.id, value: item.children[0], depth: item.attributes.level });
    }
    else if(item.name && item.name !== 'Tabs' && item.children.length > 1)
    {
      generateHeadings(item, headings, rightNavItems);
    }
  });
}


function mdoc(mdocData, serverData) {
  const {fields,frontmatter} = mdocData.markdoc;
  const {excerpt} = mdocData.markdownRemark;
  const config = {
    ...customConfig,
    variables: {
      markdoc:{
        frontmatter: {
          title: frontmatter.title
        }
      },
    }
  };

  let slug='';
  if(fields && fields.slug){
   slug=fields.slug;
  }
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      let hash = document.location.hash;
      if (hash && hash.charAt(hash.length - 1) === '/') {
        hash = hash.substr(0, hash.length - 1);
      }
      appendRouteToUrl(hash);
    }
  }, [typeof document !== 'undefined' ? document.location.hash : null]);  

  const rightNavItems = [] as any;
  const headings = [] as any;

  const ast = Markdoc.parse(serverData.pageData);
  const content = Markdoc.transform(ast, config);
  generateHeadings(content, headings, rightNavItems);

  return (
    <>
      <SEO {...buildSEO(frontmatter, excerpt, headings)} />
      <Box sx={useStyles.outerBox}>
        <InnerBox>
          <Box sx={useStyles.innerBox}>
            <Box
              sx={
                rightNavItems.length > 0
                  ? useStyles.centerBoxWithRightNav
                  : useStyles.centerBoxWithNoRightNav
              }
            >
              <Box className="mdoc-templete">
                {Markdoc.renderers.react(content, React, {
                  components: {
                    ...components,
                  }
                })}
              </Box>
            </Box>
            {rightNavItems.length > 0 && 
              <SideBar>
                <Box>
                  <RightBar>
                    <Box>
                      <RightNavBar rightNavItems={rightNavItems} pathName={slug}/>
                    </Box>
                  </RightBar>
                </Box>
              </SideBar>
            }
          </Box>
        </InnerBox>
      </Box>
    </>
  );
}

export default React.memo(({ data, serverData }:any) => {
  const pageDetails= serverData.data;
  if(serverData.fileNotFound && !serverData.downloadableFile)
  {
    return <NotFoundPage/>;
  }
  else if(pageDetails && pageDetails.markdoc && !serverData.downloadableFile)
  {
    if(serverData.isPrivate)
    {
      return getPrivatePage(serverData.pageData);
    }
    else {
      return mdoc(pageDetails, serverData);
    }
  }
  else if(serverData.downloadableFile){
    React.useEffect(() => {
      if (typeof window !== 'undefined') {
        const blob = new Blob([serverData.downloadableFile], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = serverData.fileName;
        link.click();
        setTimeout(() => window.close());
      }
    },[serverData.downloadableFile, serverData.fileName]);
  }
  return <></>;
});

const isNotValidUrl = (url) => {
  const validUrlPattern = /(%3C|%3E|%22|%27|%3B|%2F|<|>|"|;|'|javascript:|data:|base64|eval|expression|alert|onerror|onload|script|iframe|500)/i; 
  return validUrlPattern.test(url);
};

export async function getServerData(props) {

    const sideNav = readFileSync(`./src/utils/sideNavItems.json`, 'utf8');
    const sideNavItem = JSON.parse(sideNav);

    const route = readFileSync(`./src/docs-config/src/constants/routes.json`, 'utf8');
    const routes = JSON.parse(route);

    const sideNavItems = replaceRoutes(sideNavItem, routes);

    const path = require('path'); 
    const slug = props.params['*'];
    props.pageContext.slug = slug;
    let file;
    let fileNotFound = false;
    let pageTitle= null;
    let doctype= null;
    let title;
    let sdkSideNavList;
    let type = 'tsx';
    let downloadableFile;
    let fileName = null;

    if (isNotValidUrl(slug)) {
      return {
        props: {
          fileNotFound: true,
        },
        headers: {
          'Cache-Control': 'public, max-age=10, s-maxage=60, stale-while-revalidate=240',
        }
      };
    }

    if (slug.includes('blob-doc')) {
      fileName = slug.replace(/\/|blob-doc/g, '');
      if (existsSync(`./src/pages/files/${fileName}`)) {
        downloadableFile = readFileSync(`./src/pages/files/${fileName}`, 'utf8');
      }
    } else if (props.pageContext.slug.includes('blob-doc')) {
      fileName = props.pageContext.slug.replace(/\/|blob-doc/g, '');
      if (existsSync(`./src/pages/files/${fileName}`)) {
        downloadableFile = readFileSync(`./src/pages/files/${fileName}`, 'utf8');
      }
    }

  const pathArray = slug.split('/');

  const removeTrailingslash = (url) => {
    if (url && url.charAt(url.length - 1) === '/') {
      return url.substr(0, url.length - 1);
    }
    return url;
  };

  const setTitle = (element) => {
    if(element.public){
      return true;
    }
    title = element.label;
    return false;
  };

  const isPublicRouteInArticlePages = (currentRoute, sideNavItems) => {
    if (sideNavItems) {
      const res = sideNavItems.filter((element) =>
        removeTrailingslash(element.route) === removeTrailingslash(currentRoute) || currentRoute?.includes('/content/docs') && currentRoute?.includes(element.route)
          ? setTitle(element)
          : isPublicRouteInArticlePages(currentRoute, element.child),
      );
      return res.length > 0 ? true : false;
    }
    return false;
  };

  function readFolderStructure(folderPath, relativePath) {
    const folderContents = readdirSync(folderPath);
    const list:any = [];
    folderContents.forEach((item) => {
      const folderObject:any = {};
      const itemPath = path.join(folderPath, item);
      const isDirectory = statSync(itemPath).isDirectory();

      folderObject.label = item.charAt(0).toUpperCase() + item.slice(1);
      if (isDirectory) {
        folderObject.child = readFolderStructure(itemPath, relativePath + item + '/');
      } else {
        const fileName = path.parse(item).name; 
        folderObject.label = fileName.charAt(0).toUpperCase() + fileName.slice(1);
        folderObject.route = relativePath + fileName + '/';
      }

      list.push(folderObject);
    });
    return list;
  }

  let cookies:any = [];
  if(props.headers.get('cookie'))
  {
    cookies = props.headers.get('cookie').split(';');
  }
  let sessionId = '';
  for (const element of cookies) {
    if (element.trim().match('^session-id=')) {
      sessionId = element.replace('session-id=', '').trim();
    }
  }

  const session = async() => {
    return fetch(`${process.env.GATSBY_OKTA_DOMAIN_URL}/api/v1/sessions/${sessionId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `SSWS ${process.env.GATSBY_OKTA_API_KEY}`
      }
    })
    .then((response) => response.json())
    .then((data) => {
      if(data && data.status === 'ACTIVE')
      {
        return true;
      }
      else{
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
  };

  let isAuthenticated = false;
  if (sessionId && await session()) {
    isAuthenticated = true;
  }
  const baseSettings = await getBaseSettings(isAuthenticated);

  let isPrivate = false;
  try {
    if(existsSync(`./src/pages/content/docs/${slug}.md`)){
      logger.debug(`File found:`, {file_name: slug});
      if(isPublicRouteInArticlePages(`/${slug}/`, sideNavItems))
      {
        file = readFileSync(`./src/pages/content/docs/${slug}.md`, 'utf8');
      }
      else{
        if(sessionId && await session())
        {
          file = readFileSync(`./src/pages/content/docs/${slug}.md`, 'utf8');
        }
        else {
          if(pathArray[0] === 'sdks') {
            file = title || pathArray.slice(-1);
          }
          else {
            file = title || slug.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
          }
          isPrivate = true;
        }
      }
      
      if(pathArray[0] === 'sdks') {
        type = 'sdk-ref';
        const relativePath = pathArray.slice(0, 2).join('/');
        const folderPath = './src/pages/content/docs/' + relativePath;
    
        const pathData = readFolderStructure(folderPath, `/${relativePath}/`);
        const sortedPathData = sortSDKNavItems(pathData, pathArray[1]);
        sdkSideNavList = [{label: 'Home', route: '/'}, ...sortedPathData];
      }
    }
    else {
      fileNotFound = true;
      logger.error(`File not found`, {file_name: slug});
    }
  }
  catch(e) {
    fileNotFound = true;
    logger.error('Error occurred while processing file', {error: JSON.stringify(e), file_name: slug});
  }

  if(!fileNotFound){
    const titleMatch = file.match(/^title: (.+)$/m);
    pageTitle = titleMatch ? titleMatch[1] : null;

    const doctypeMatch = file.match(/^docType: (.+)$/m);
    doctype = doctypeMatch ? doctypeMatch[1] : null;
  }
 
  const data= {
    markdoc: {
      fields: {slug: slug},
      frontmatter: {
        title: pageTitle,
        docType: doctype
      }
    },
    markdownRemark: {
      excerpt: ''
    }
   };

  return {
    props: {
      pageData: file,
      fileNotFound: fileNotFound,
      isPrivate: isPrivate,
      data: data,
      sdkSideNavList: sdkSideNavList || [],
      clientSideNavList: sideNavItems,
      type: type,
      downloadableFile: downloadableFile,
      fileName: fileName,
      baseSettings: baseSettings,
    },
    headers: {
      'Cache-Control': 'public, max-age=10, s-maxage=60, stale-while-revalidate=240',
    }
  };
}
